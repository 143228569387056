.arrow-up-animation {
    animation: arrow-up 1.5s ease-in-out infinite;
}

@keyframes arrow-up {
    0% {
        transform: translateY(-5px) rotate(-12deg) scale(1.0);
    }
    50% {
        transform: translateY(0) rotate(-18deg) scale(0.97);
    }
    100% {
        transform: translateY(-5px) rotate(-12deg) scale(1.0);
    }
}